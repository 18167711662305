var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-course"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'myClasses' }}},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.myClasses"))+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('el-skeleton',{attrs:{"loading":!_vm.pageTitle,"animated":""}},[_c('template',{slot:"template"},[_c('el-skeleton-item',{staticStyle:{"line-height":"1.5","width":"50%"}})],1),[_c('Heading',{attrs:{"heading":"h4","content":_vm.pageTitle}},[_c('div',{attrs:{"slot":"button"},slot:"button"},[(_vm.roleIsTeacherOrAdmin)?_c('router-link',{attrs:{"to":{
              name: 'sessionClassesEdit',
              params: { id: _vm.$route.params.classId }
            }}},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fa fa-edit"})])],1):_vm._e(),(_vm.roleIsTeacherOrAdmin)?_c('router-link',{staticClass:"ml-2",attrs:{"to":{
              name: 'sessionClassesCreateLog',
              params: { id: _vm.$route.params.classId },
              query: { lType: 'MG' }
            }}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Add Meet and Greet","placement":"top"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fas fa-plus"})])],1)],1):_vm._e()],1)])]],2),_c('hr'),_c('el-menu',{staticClass:"course-menu mb-3 mt-3",attrs:{"default-active":_vm.activeSection,"mode":"horizontal","background-color":"#f3f3f3"},on:{"select":_vm.showSection}},[_c('el-menu-item',{attrs:{"index":"ClassInfo"}},[_c('i',{staticClass:"el-icon-s-home"}),_vm._v(" "+_vm._s(_vm.$t("newCourses.Info"))+" ")]),(_vm.isRoleAdmin())?_c('el-menu-item',{attrs:{"index":"ClassStudents"}},[_c('i',{staticClass:"el-icon-user-solid"}),_vm._v(" Students ")]):_vm._e(),_c('el-menu-item',{attrs:{"index":"ClassAttendance"}},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" "+_vm._s(_vm.$t("newCourses.Attendance"))+" ")]),_c('el-menu-item',{attrs:{"index":"ClassMaterials"}},[_c('i',{staticClass:"el-icon-folder-opened"}),_vm._v(" "+_vm._s(_vm.$t("newCourses.Materials"))+" ")]),_c('el-menu-item',{attrs:{"index":"ClassTests"}},[_c('i',{staticClass:"el-icon-s-data"}),_vm._v(" Full Tests ")]),_c('el-menu-item',{attrs:{"index":"ClassPractices"}},[_c('i',{staticClass:"el-icon-s-data"}),_vm._v(" Modules & Drills ")]),_c('el-menu-item',{attrs:{"index":"ClassLessonLogs"}},[_c('i',{staticClass:"el-icon-s-order"}),_vm._v(" "+_vm._s(_vm.$t("newCourses.Lesson Logs"))+" ")]),_c('el-menu-item',{attrs:{"index":"ClassProgressReport"}},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" "+_vm._s(_vm.$t("newCourses.Progress Report"))+" ")])],1),_c('div',[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }