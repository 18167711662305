<template>
  <div class="group-course">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'myClasses' }">
            {{ $t("pageTitle.myClasses") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>

    <el-skeleton :loading="!pageTitle" animated>
      <template slot="template">
        <el-skeleton-item style="line-height:1.5;width: 50%;" />
      </template>
      <template>
        <Heading heading="h4" :content="pageTitle">
          <div slot="button">
            <router-link
              v-if="roleIsTeacherOrAdmin"
              :to="{
                name: 'sessionClassesEdit',
                params: { id: $route.params.classId }
              }"
            >
              <el-button type="primary" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
            <router-link
              class="ml-2"
              v-if="roleIsTeacherOrAdmin"
              :to="{
                name: 'sessionClassesCreateLog',
                params: { id: $route.params.classId },
                query: { lType: 'MG' }
              }"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="Add Meet and Greet"
                placement="top"
              >
                <el-button type="primary" size="mini">
                  <i class="fas fa-plus" />
                </el-button>
              </el-tooltip>
            </router-link>
          </div>
        </Heading>
      </template>
    </el-skeleton>
    <hr />
    <el-menu
      :default-active="activeSection"
      class="course-menu mb-3 mt-3"
      mode="horizontal"
      @select="showSection"
      background-color="#f3f3f3"
    >
      <el-menu-item index="ClassInfo">
        <i class="el-icon-s-home"></i>
        {{ $t("newCourses.Info") }}
      </el-menu-item>
      <el-menu-item index="ClassStudents" v-if="isRoleAdmin()">
        <i class="el-icon-user-solid"></i>
        Students
      </el-menu-item>
      <el-menu-item index="ClassAttendance">
        <i class="el-icon-date"></i>
        {{ $t("newCourses.Attendance") }}
      </el-menu-item>
      <el-menu-item index="ClassMaterials">
        <i class="el-icon-folder-opened"></i>
        {{ $t("newCourses.Materials") }}
      </el-menu-item>
      <el-menu-item index="ClassTests">
        <i class="el-icon-s-data"></i>
        Full Tests
      </el-menu-item>
      <el-menu-item index="ClassPractices">
        <i class="el-icon-s-data"></i>
        Modules & Drills
      </el-menu-item>
      <el-menu-item index="ClassLessonLogs">
        <i class="el-icon-s-order"></i>
        {{ $t("newCourses.Lesson Logs") }}
      </el-menu-item>
      <el-menu-item index="ClassProgressReport">
        <i class="el-icon-date"></i>
        {{ $t("newCourses.Progress Report") }}
      </el-menu-item>
    </el-menu>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";
import profileTest from "@/mixins/profileTest";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [roleMixin, profileTest],

  props: [],
  data() {
    return {
      activeSection: "details"
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    ...mapGetters("session", ["getSessionTitle"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    pageTitle() {
      return this.getSessionTitle;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    roleIsTeacher() {
      return this.isRoleTeacher();
    },
    roleIsAdmin() {
      return this.isRoleAdmin();
    }
  },
  watch: {},

  async mounted() {
    this.activeSection = this.$route.name;
    if (this.$route.name === "ClassInfo") {
      await this.$store.commit("session/setSessionTitle", null);
    }
    await this.$store.dispatch(
      "session/getSessionTitle",
      this.$route.params.classId
    );
  },

  methods: {
    showSection(index) {
      this.$router.replace({
        name: index,
        params: {
          ...this.$route.params
        }
      });
    }
  }
};
</script>
<style scoped>
.course-menu {
  margin-bottom: 20px;
}
.separate-line {
  display: none;
}
body /deep/ .el-tooltip__popper.is-dark {
  background: #42a16a;
}
.group-course {
  padding: 20px;
}
.add-button {
  display: none;
}
.add-result:hover .add-button {
  display: inline-block;
}

.controlAttendanceButtons {
  display: flex;
}

.controlAttendanceButtons > button {
  margin: 0px 8px;
}

.editIcon {
  font-size: 26px;
}
.class-info p {
  display: flex;
}
.class-info p b {
  display: inline-block;
  width: 5rem;
}
</style>
